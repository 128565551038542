
































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CardRow from '@/components/CardRow.vue';
import { FETCH_DETAILS, GET_BY_ID } from '../store';
import { Appointment, AppointmentView } from '../types';
import { MapStateToView } from '@/modules/appointment/model';
import { AuthorizationMixin } from '@/views/mixin';
import { ApiResponse } from '@/components/types';

const Appointment = namespace('appointment');

@Component({
  components: { CardRow }
})
export default class ViewAppointmentDialog extends AuthorizationMixin {
  @Prop({ type: String }) appointmentId?: string;

  @Appointment.Getter(GET_BY_ID) getById!: (id: string) => Appointment | undefined;
  @Appointment.Action(FETCH_DETAILS) fetchAppointment!: (appointment: string) => Promise<ApiResponse<Appointment>>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  appointment: AppointmentView | null = null;

  @Watch('appointmentId', { immediate: true })
  async onAppointmentId(appointmentId?: string) {
    if (!appointmentId) return;

    const appointment = this.getById(appointmentId);

    if (appointment) {
      this.appointment = MapStateToView(appointment);
      return;
    }

    const { content, error } = await this.fetchAppointment(appointmentId);

    if (content && !error) {
      this.appointment = MapStateToView(content);
    }
  }

  @Watch('appointment', { immediate: true })
  onAppointmentIdChange(appointment?: AppointmentView) {
    if (!appointment) return;

    this.dialog = true;
  }

  @Watch('dialog')
  onDialogClose(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:appointmentId', '');
    }, 200);
  }

  appointmentDate(appointment: AppointmentView): string {
    if (appointment.endDate !== appointment.beginDate) {
      return `${appointment.beginDate} - ${appointment.endDate}`;
    }

    return appointment.beginDate;
  }

  tooltip(appointment: AppointmentView): string {
    if (appointment.start === appointment.begin) {
      return '';
    }

    return `Abfahrt: ${appointment.startTime}`;
  }

  onEdit(appointment: AppointmentView) {
    this.dialog = false;

    setTimeout(() => {
      this.$emit('edit', appointment.id);
      this.$emit('update:appointmentId', '');
    }, 100);
  }

  onCancel(appointment: AppointmentView) {
    this.dialog = false;

    setTimeout(() => {
      this.$emit('cancel', appointment.id);
      this.$emit('update:appointmentId', '');
    }, 100);
  }
}
