




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import 'fullcalendar/dist/fullcalendar.css';
import defaultsDeep from 'lodash.defaultsdeep';
import 'fullcalendar/dist/locale/de.js';
import 'fullcalendar';
import $ from 'jquery';

@Component({})
export default class FullCalendar extends Vue {
  @Prop({ type: [Array, String], default: () => [] }) events: any;
  @Prop({ type: Array, default: () => [] }) eventSources: any;
  @Prop({
    type: Object, default: () => {
    }
  }) config: any;
  @Prop({ type: Boolean, default: false }) editable: any;
  @Prop({ type: Boolean, default: false }) selectable: any;
  @Prop({ type: Boolean, default: true }) selectHelper: any;
  @Prop({ type: Boolean, default: false }) sync: any;
  @Prop({ type: String, default: 'agendaWeek' }) defaultView: any;
  @Prop({
    type: Object,
    default: () => ({ left: 'prev,today,next', right: 'agendaDay,agendaWeek', center: 'title' })
  }) header: any;

  get defaultConfig(): any {
    const self = this;
    const cal = $(this.$el);

    return {
      header: this.header,
      defaultView: this.defaultView,
      editable: this.editable,
      selectable: this.selectable,
      selectHelper: this.selectHelper,
      aspectRatio: 2,
      timeFormat: 'HH:mm',
      events: this.events,
      eventSources: this.eventSources,
      firstDay: 1,
      allDaySlot: false,
      slotLabelFormat: 'HH:mm',
      minTime: '06:00:00',
      maxTime: '20:00:00',
      hiddenDays: [0, 6],
      nowIndicator: true,

      viewRender() {
        self.$emit('date-changed', cal.fullCalendar('getDate').format('YYYY-MM-DD'));
      },

      eventRender(...args: any) {
        if (this.sync) {
          // @ts-ignore
          self.events = cal.fullCalendar('clientEvents');
        }
        self.$emit('event-render', ...args);
      },

      eventDestroy() {
        if (this.sync) {
          // @ts-ignore
          self.events = cal.fullCalendar('clientEvents');
        }
      },

      eventClick(...args: any) {
        self.$emit('event-selected', ...args);
      },

      eventDrop(...args: any) {
        self.$emit('event-drop', ...args);
      },

      eventResize(...args: any) {
        self.$emit('event-resize', ...args);
      },

      dayClick(...args: any) {
        self.$emit('day-click', ...args);
      },

      select(start: any, end: any, jsEvent: any, view: any, resource: any) {
        self.$emit('event-created', {
          start,
          end,
          allDay: !start.hasTime() && !end.hasTime(),
          view,
          resource
        });
      }
    };
  }

  mounted() {
    const cal = $(this.$el);

    this.$on('remove-event', (event: any) => {
      if (event && event.hasOwnProperty('id')) {
        $(this.$el).fullCalendar('removeEvents', event.id);
      } else {
        $(this.$el).fullCalendar('removeEvents', event);
      }
    });

    this.$on('rerender-events', () => {
      $(this.$el).fullCalendar('rerenderEvents');
    });

    this.$on('refetch-events', () => {
      $(this.$el).fullCalendar('refetchEvents');
    });

    this.$on('render-event', (event: any) => {
      $(this.$el).fullCalendar('renderEvent', event);
    });

    this.$on('reload-events', () => {
      $(this.$el).fullCalendar('removeEvents');
      $(this.$el).fullCalendar('addEventSource', this.events);
    });

    this.$on('rebuild-sources', () => {
      // @ts-ignore
      $(this.$el).fullCalendar('removeEventSources');

      this.eventSources.map((event: any) => {
        $(this.$el).fullCalendar('addEventSource', event);
      });
    });

    cal.fullCalendar(defaultsDeep(this.config, this.defaultConfig));
  }

  fireMethod(...options: any) {
    return $(this.$el).fullCalendar(...options);
  }

  @Watch('events', { deep: true })
  onEventChange() {
    $(this.$el).fullCalendar('removeEvents');
    $(this.$el).fullCalendar('addEventSource', this.events);
  }

  @Watch('eventSources', { deep: true })
  onEventSourcesChange() {
    $(this.$el).fullCalendar('removeEvents');
    $(this.$el).fullCalendar('addEventSource', this.events);
  }

  @Watch('defaultView')
  onDefaultViewChange(view: any) {
    $(this.$el).fullCalendar('changeView', view);
  }

  beforeDestroy() {
    this.$off('remove-event');
    this.$off('rerender-events');
    this.$off('refetch-events');
    this.$off('render-event');
    this.$off('reload-events');
    this.$off('rebuild-sources');
  }
}
