










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_APPOINTMENT, GET_CREATE, SET_CREATE } from '../store';
import AppointmentForm from './AppointmentForm.vue';
import { WriteAppointment } from '@/modules/appointment/types';
import { ApiResponse } from '@/components/types';

const AppointmentStore = namespace('appointment');

@Component({
  components: {
    AppointmentForm
  }
})
export default class CreateAppointmentDialog extends Vue {
  @Prop({ type: Boolean, default: false }) carRequired!: boolean;

  @AppointmentStore.Action(ADD_APPOINTMENT) addAppointment!: (appointment: WriteAppointment) => Promise<ApiResponse>;
  @AppointmentStore.Getter(GET_CREATE) getCreate!: WriteAppointment | null;
  @AppointmentStore.Mutation(SET_CREATE) setCreate!: (appointment: WriteAppointment | null) => void;

  error: any = null;
  dialog: boolean = false;

  get appointment(): WriteAppointment | null {
    return this.getCreate;
  }

  set appointment(appointment: WriteAppointment | null) {
    this.setCreate(appointment);
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => this.clear(), 200);
  }

  @Watch('appointment', { immediate: true })
  watchAppointment(appointment: WriteAppointment | null) {
    if (appointment !== null && this.dialog === false) {
      this.dialog = true;
    }
  }

  onSuccess() {
    this.$emit('success', this.appointment);
    this.dialog = false
  }

  clear() {
    this.error = null;
    this.appointment = null;
  }

  save() {
    this.error = null;

    if (this.appointment === null) return;

    return this.addAppointment(this.appointment);
  }
}
